





import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import BaseForm from '@/components/BaseForm.vue';
import {
  FormElement,
  FormElementType,
  FormElementOptions,
} from '@/shared/types/form-element.class';
import { Asset, ControlType } from '@/api/assets/asset.model';
import { KeyWi } from '@/api/keywis/keywi.model';
import { namespace } from 'vuex-class';
import {
  CastType,
  Filter,
  FilterOperation,
  Operator,
} from '@/shared/types/filter.class';
import { SaltoLock } from '@/api/salto-locks/salto-lock.model';
import { isUndefined } from 'lodash';

const keywisModule = namespace('keywis');
const saltoLocksModule = namespace('saltoLocks');
const appModule = namespace('app');
// The @Component decorator indicates the class is a Vue component
@Component({
  components: {
    BaseForm,
  },
})
export default class AssetForm extends Vue {
  @Prop()
  asset!: Asset;

  @appModule.Mutation('addLoader')
  addLoader!: (id: string) => void;

  @appModule.Mutation('removeLoader')
  removeLoader!: (id: string) => void;

  @keywisModule.Getter('all')
  keywis!: KeyWi[];

  @saltoLocksModule.Getter('all')
  locks!: SaltoLock[];

  @keywisModule.Mutation('emptyAll')
  emptyKeywis!: () => void;

  @saltoLocksModule.Mutation('emptyAll')
  emptySaltoLocks!: () => void;

  @keywisModule.Action('fetchAll')
  fetchAllKeywis!: (filter?: Filter) => Promise<KeyWi[]>;

  @saltoLocksModule.Action('fetchAll')
  fetchAllSaltoLocks!: (filter?: Filter) => Promise<SaltoLock[]>;

  get formElements() {
    return [
      [
        new FormElement(
          'timezone',
          'Timezone',
          new FormElementOptions(
            ['Europe/Brussels'],
            undefined,
            undefined,
            undefined,
            !this.canUpdate,
          ),
          '',
          FormElementType.SELECT,
        ),
      ],
      [
        new FormElement(
          'controlType',
          'Controller type',
          new FormElementOptions(Object.values(ControlType)),
          '',
          FormElementType.SELECT,
        ),
        new FormElement(
          'controller',
          'Controller',
          new FormElementOptions(
            [
              {
                _id: null,
                name: 'No controller',
                // eslint-disable-next-line @typescript-eslint/camelcase
                customer_reference: 'No controller',
              },
              ...this.controllers,
            ],
            this.label,
            '_id',
            undefined,
            !this.canUpdate,
          ),
          '',
          FormElementType.SELECT,
        ),
      ],
      [
        new FormElement(
          'image',
          'Image',
          new FormElementOptions(
            undefined,
            undefined,
            undefined,
            undefined,
            !this.canUpdate,
          ),
          '',
          FormElementType.IMAGE,
        ),
      ],
      [
        new FormElement(
          'pricePerHour',
          'Price per hour',
          new FormElementOptions(
            undefined,
            undefined,
            undefined,
            undefined,
            !this.canUpdate,
          ),
          'required|digits',
          FormElementType.NUMBER,
        ),
        new FormElement(
          'bookingBufferInMinutes',
          'Buffer',
          new FormElementOptions(
            undefined,
            undefined,
            undefined,
            undefined,
            !this.canUpdate,
            'Buffer after the booking ends before another person can book (in minutes)',
          ),
          'required|integer',
          FormElementType.TEXT,
        ),
      ],
      [
        new FormElement(
          'minimumDurationInMinutes',
          'Minimum Duration (min)',
          new FormElementOptions(
            undefined,
            undefined,
            undefined,
            undefined,
            !this.canUpdate,
          ),
          '',
        ),
        new FormElement(
          'expiresAfterInMinutes',
          'Expire after (min)',
          new FormElementOptions(
            undefined,
            undefined,
            undefined,
            undefined,
            !this.canUpdate,
          ),
          '',
        ),
      ],
      [
        new FormElement(
          'finePerHour',
          'Fine per hour',
          new FormElementOptions(
            undefined,
            undefined,
            undefined,
            undefined,
            !this.canUpdate,
          ),
          '',
        ),
        new FormElement(
          'fineAfterInMinutes',
          'Fine after (min)',
          new FormElementOptions(
            undefined,
            undefined,
            undefined,
            undefined,
            !this.canUpdate,
          ),
          '',
        ),
      ],
      [
        new FormElement(
          'autoEnd',
          'Automatically end',
          new FormElementOptions(
            undefined,
            undefined,
            undefined,
            undefined,
            !this.canUpdate,
          ),
          '',
          FormElementType.CHECKBOX,
        ),
        new FormElement(
          'enforceClose',
          'Enforce asset closed before ending booking',
          new FormElementOptions(
            undefined,
            undefined,
            undefined,
            undefined,
            !this.canUpdate,
          ),
          '',
          FormElementType.CHECKBOX,
        ),
      ],
    ];
  }

  get canUpdate() {
    return this.$can('update', 'Asset');
  }

  get controllers() {
    if (this.asset.controlType === ControlType.KEYWI) {
      return this.keywis;
    }
    return this.locks;
  }

  get label() {
    if (this.asset.controlType === ControlType.KEYWI) {
      return 'name';
    }
    return 'customer_reference';
  }

  fetchAllControllers() {
    this.addLoader('app');
    if (!this.asset?.project) {
      this.emptyKeywis();
      this.emptySaltoLocks();
      this.removeLoader('app');
      return;
    }
    const filter = new Filter([
      new FilterOperation(
        'project',
        this.asset.project,
        Operator.Equal,
        CastType.ObjectId,
      ),
    ]);
    if (this.asset.controlType === ControlType.KEYWI) {
      return this.fetchAllKeywis(filter).finally(() => {
        this.removeLoader('app');
      });
    }
    return this.fetchAllSaltoLocks(filter).finally(() => {
      this.removeLoader('app');
    });
  }

  @Watch('asset', { immediate: true })
  async onAssetChanged(newProject: string, oldProject: string) {
    await this.fetchAllControllers();
  }

  @Watch('asset.project')
  async onProjectChanged(newProject: string, oldProject: string) {
    if (oldProject !== undefined && newProject !== oldProject) {
      this.asset.controller = null;
      await this.fetchAllControllers();
    }
  }

  @Watch('asset.controlType')
  async onControlTypeChanged(newControlType: string, oldControlType: string) {
    if (oldControlType !== undefined && newControlType !== oldControlType) {
      this.asset.controller = null;
      await this.fetchAllControllers();
    }
  }

  async created() {
    await this.fetchAllControllers();
  }
}
