import { BaseModel } from '@/shared/types/base.model';
import { File } from '@/shared/types/file.model';
import { Location } from './location.model';

export enum ControlType {
  KEYWI = 'Keywi',
  SALTO_LOCK = 'SaltoLock',
}

export enum FuelType {
  DIESEL = 'Diesel',
  PETROL = 'Petrol',
  ELECTRIC = 'Electric',
  HYBRID = 'Hybrid',
  PLUGIN_HYBRID = 'PluginHybrid',
  HYDROGEN = 'Hydrogen',
  CNG = 'Cng',
  UNKNOWN = 'Unknown',
}

export enum CarType {
  SEDAN = 'Sedan',
  VAN = 'Van',
  SUV = 'Suv',
  OFFROAD = 'Offroad',
  UNKNOWN = 'Unknown',
}

export enum AssetType {
  CAR = 'Car',
  MEETING_ROOM = 'MeetingRoom',
  DOOR = 'Door',
}

export enum AssetState {
  Blocked,
  Active,
}

export class AssetDetail {
  constructor(public key: string, public value: string = '') {}
}

export class Asset extends BaseModel {
  constructor(
    public name?: string,
    public type?: string,
    public project: string = null,
    public company?: string,
    public state: AssetState = AssetState.Active,
    public description?: string,
    public location?: Location,
    public pricePerHour?: number,
    public price?: string,
    public keyWi?: string,
    public bookingBufferInMinutes?: number,
    public details?: AssetDetail[],
    public minimumDurationInMinutes?: number,
    public expiresAfterInMinutes?: number,
    public fineAfterInMinutes?: number,
    public autoEnd?: boolean,
    public enforceClose?: boolean,
    public finePerHour?: number,
    public image?: File,
    public timezone: string = 'Europe/Brussels',
    public controlType: ControlType = ControlType.KEYWI,
    public controller?: string,
    public active?: boolean,
  ) {
    super();
  }
}
