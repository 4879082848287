




























import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { BModal } from 'bootstrap-vue';
import { Watch } from 'vue-property-decorator';

import AssetsTable from '../components/AssetsTable.vue';
import BaseHeader from '../components/BaseHeader.vue';

import { Filter } from '../shared/types/filter.class';

import { Asset } from '../api/assets/asset.model';
import { Category } from '../api/categories/category.model';

const assetsModule = namespace('assets');
const categoriesModule = namespace('categories');

// The @Component decorator indicates the class is a Vue component
@Component({ components: { BaseHeader, AssetsTable } })
export default class Assets extends Vue {
  $refs!: Vue['$refs'] & {
    assetDetailModal: BModal;
    createAssetModal: BModal;
  };
  tabIndex = 0;

  filter: Filter = new Filter();

  @categoriesModule.Getter('all')
  allCategories!: Category[];

  @categoriesModule.Action('fetchAll')
  fetchAllCategories!: (filter?: Filter) => Promise<Category[]>;

  @assetsModule.Action('delete')
  deleteAsset!: (id: string) => Promise<Asset>;

  @Watch('tabIndex')
  onTabChanged(tabIndex: number) {
    this.filter.removeForKey('type');
    if (tabIndex > 0) {
      const index = tabIndex - 1;
      this.filter.addFilter('type', this.allCategories[index]._id);
    }
  }

  openCreate() {
    this.$router.push({ name: 'NewAsset' });
  }

  async created() {
    await this.fetchAllCategories();
  }
}
