



































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Company } from '../../api/companies/company.model';
import { Project } from '../../api/projects/project.model';
import { Asset } from '../../api/assets/asset.model';
import { Filter } from '../../shared/types/filter.class';
import moment from 'moment-timezone';
import { Category } from '@/api/categories/category.model';

import GeneralInformationListItem from '../GeneralInformationListItem.vue';

const companiesModule = namespace('companies');
const projectsModule = namespace('projects');
const assetsModule = namespace('assets');
const categoriesModule = namespace('categories');

// The @Component decorator indicates the class is a Vue component
@Component({
  components: { GeneralInformationListItem },
})
export default class GeneralInformation extends Vue {
  colorMapping = {
    0: 'secondary',
    1: 'dark',
  };

  @Prop()
  asset!: Asset;

  get canUpdate() {
    return this.$can('update', 'Asset');
  }

  get state() {
    return this.asset?.state;
  }

  get stateVariant() {
    return this.colorMapping[this.state];
  }

  get mapCenter() {
    return {
      lat: this.asset?.location?.latitude,
      lng: this.asset?.location?.longitude,
    };
  }

  get assetName() {
    if (this.asset) {
      return this.asset.name;
    }
    return 'N/A';
  }

  get createdAt() {
    return moment
      .utc(this.asset?.createdAt)
      .format('ddd, DD-MM-YYYY HH:mm (Z)');
  }

  get updatedAt() {
    return moment
      .utc(this.asset?.updatedAt)
      .format('ddd, DD-MM-YYYY HH:mm (Z)');
  }

  get canReadCompany() {
    return this.$can('read', 'Company');
  }

  get canReadProject() {
    return this.$can('read', 'Project');
  }

  @categoriesModule.Getter('all')
  categories!: Category[];

  @assetsModule.Getter('loggedInAsset')
  loggedInAsset!: Asset;

  @projectsModule.Getter('all')
  projects!: Project[];

  @companiesModule.Getter('all')
  companies!: Company[];

  @projectsModule.Action('fetchAll')
  fetchAllProjects!: (filter?: Filter) => Promise<Project[]>;

  @companiesModule.Action('fetchAll')
  fetchAllCompanies!: (filter?: Filter) => Promise<Company[]>;

  @categoriesModule.Action('fetchAll')
  fetchCategories!: (filter?: Filter) => Promise<Category[]>;

  openPayment() {
    window.alert('OPEN PAY');
  }

  async created() {
    await this.fetchCategories();
    if (this.canReadCompany) {
      await this.fetchAllCompanies();
    }
    if (this.canReadProject) {
      await this.fetchAllProjects();
    }
  }
}
