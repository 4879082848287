





import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import BaseForm from '@/components/BaseForm.vue';
import {
  FormElement,
  FormElementType,
  FormElementOptions,
} from '@/shared/types/form-element.class';
import { Asset, AssetDetail } from '@/api/assets/asset.model';
import { KeyWi } from '@/api/keywis/keywi.model';
import { Project } from '@/api/projects/project.model';
import { Filter, FilterOperation } from '@/shared/types/filter.class';
import { Category } from '@/api/categories/category.model';
import { Company } from '@/api/companies/company.model';

const companiesModule = namespace('companies');
const projectsModule = namespace('projects');
const categoriesModule = namespace('categories');

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {
    BaseForm,
  },
})
export default class AssetDetailForm extends Vue {
  @Prop()
  asset!: Asset;

  @categoriesModule.Getter('all')
  categories!: Category[];

  show = false;

  formElements: FormElement[][] = [];
  baseFormElements: FormElement[][] = [
    [
      new FormElement(
        'name',
        'Name',
        new FormElementOptions(
          undefined,
          undefined,
          undefined,
          undefined,
          !this.canUpdate,
        ),
        'required',
      ),
      new FormElement(
        'description',
        'Description',
        new FormElementOptions(
          undefined,
          undefined,
          undefined,
          undefined,
          !this.canUpdate,
        ),
      ),
    ],
  ];

  @categoriesModule.Action('fetchAll')
  fetchCategories!: (filter?: Filter) => Promise<Category[]>;

  get canUpdate() {
    return this.$can('update', 'Asset');
  }

  @Watch('asset.type', {
    immediate: true,
  })
  onAssetTypeChanged(newValue: string, oldValue?: string) {
    const fullCat = this.categories.find(cat => newValue === cat._id);
    this.formElements = [...this.baseFormElements];
    if (!fullCat) {
      return;
    }

    let row: FormElement[] = [];
    if (oldValue && oldValue !== newValue) {
      this.asset.details = [];
    }
    let i = 0;
    for (const field of fullCat.fields) {
      let fieldIndex = this.asset.details.findIndex(x => x.key === field.field);
      if (fieldIndex === -1) {
        this.asset.details.push(new AssetDetail(field.field));
        fieldIndex = this.asset.details.length - 1;
      }
      const options = new FormElementOptions(
        undefined,
        undefined,
        undefined,
        undefined,
        !this.canUpdate,
      );
      if (field.options) {
        options.optionsData = field.options;
      }

      row.push(
        new FormElement(
          `details.${i}.value`,
          field.field,
          options,
          '',
          field.type,
        ),
      );
      if (row.length === 2) {
        this.formElements.push(row);
        row = [];
      }
      ++i;
    }
    if (row.length > 0) {
      this.formElements.push(row);
    }
  }

  async created() {
    await this.fetchCategories();
  }
}
