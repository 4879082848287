










































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Asset, ControlType } from '../api/assets/asset.model';
import { namespace } from 'vuex-class';
import { Filter } from '../shared/types/filter.class';

import GeneralInformation from '../components/AssetDetail/GeneralInformation.vue';
import BaseHeader from '../components/BaseHeader.vue';
import AssetDetailForm from '../components/AssetDetail/AssetDetailForm.vue';
import AssetConfigurationForm from '../components/AssetDetail/AssetConfigurationForm.vue';
import BookingsTable from '../components/BookingsTable.vue';
import LogsTable from '../components/LogsTable.vue';
import { Booking } from '../api/bookings/booking.model';
import { AssetState } from '../api/assets/asset-state.enum';
import Toast from '../shared/types/toast.class';
import { ToastType } from '../shared/types/toast-type.enum';
import { SortOrder } from '../shared/types/sort-order.enum';
import { Watch } from 'vue-property-decorator';

const assetsModule = namespace('assets');
const appModule = namespace('app');
const authModule = namespace('auth');

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {
    BaseHeader,
    GeneralInformation,
    AssetDetailForm,
    BookingsTable,
    LogsTable,
    AssetConfigurationForm,
  },
})
export default class AssetDetail extends Vue {
  bookingsFilter = new Filter([], { start: SortOrder.Asc });

  detailTabs = ['Configuration', 'Category specific', 'Bookings'];
  detailTab = 'Configuration';
  currentLogTab = 'all';

  get hasActions() {
    return this.asset?.controlType === ControlType.SALTO_LOCK;
  }

  get canUpdate() {
    return this.$can('update', 'Asset');
  }

  get canDelete() {
    return this.$can('delete', 'Asset');
  }

  get isCreate() {
    return this.$route.name === 'NewAsset';
  }

  get assetName() {
    return this.isCreate ? 'New Asset' : this.asset?.name;
  }

  get isDetailActive() {
    return this.detailTab === this.detailTabs[1];
  }

  get isConfigurationActive() {
    return this.detailTab === this.detailTabs[0];
  }

  get isBookingsActive() {
    return this.detailTab === this.detailTabs[2];
  }

  get isActive() {
    return this.asset.state === AssetState.Active;
  }

  get isBlocked() {
    return this.asset.state === AssetState.Blocked;
  }

  get detailsBodyClass() {
    if (this.isBookingsActive) {
      return 'p-0';
    }
    return '';
  }

  get tabs() {
    if (!this.isCreate) {
      return this.detailTabs;
    }
    return [this.detailTabs[0], this.detailTabs[1]];
  }

  @assetsModule.Getter('current')
  asset!: Asset;

  @assetsModule.Mutation('setCurrent')
  setCurrent!: (asset: Asset) => void;

  @appModule.Getter('isLoading')
  isLoading!: (id: string) => boolean;

  @appModule.Mutation('addLoader')
  addLoader!: (id: string) => void;

  @appModule.Mutation('removeLoader')
  removeLoader!: (id: string) => void;

  @appModule.Mutation('addToast')
  addToast!: (toast: Toast) => void;

  @assetsModule.Action('fetchById')
  fetchById!: (id: string) => Promise<Asset>;

  @assetsModule.Action('update')
  update!: (asset: Asset) => Promise<Asset>;

  @assetsModule.Action('block')
  block!: (id: string) => Promise<Asset>;

  @assetsModule.Action('unblock')
  unblock!: (id: string) => Promise<Asset>;

  @assetsModule.Action('performAction')
  performAction!: (payload: { id: string; action: string }) => Promise<void>;

  @assetsModule.Action('delete')
  delete!: (id: string) => Promise<Asset>;

  @assetsModule.Action('create')
  create!: (asset: Asset) => Promise<Asset>;

  @authModule.Action('requestResetPassword')
  requestResetPassword!: (email: string) => Promise<void>;

  onLogsTabChanged(tab: string) {
    this.currentLogTab = tab;
  }

  async doSave() {
    this.addLoader('app');
    if (this.isCreate) {
      const asset = await this.create(this.asset);
      this.$router.replace({
        name: 'AssetDetail',
        params: { id: asset._id },
      });
    } else {
      await this.update(this.asset);
    }
    this.removeLoader('app');
  }

  async doDelete() {
    const result = await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to delete ${this.asset.name}?`,
      {
        title: 'Delete',
        okVariant: 'danger',
        centered: true,
      },
    );
    if (!result) return;

    this.addLoader('app');
    const toast = new Toast('Asset deleted', ToastType.SUCCESS);
    try {
      await this.delete(this.asset._id);
    } catch (e) {
      toast.message = e.message;
      toast.type = ToastType.ERROR;
    }
    this.addToast(toast);
    this.removeLoader('app');
    this.$router.go(-1);
  }

  openBooking(booking: Booking) {
    this.$router.push({ name: 'BookingDetail', params: { id: booking._id } });
  }

  async doUnlock() {
    this.addLoader('app');
    await this.performAction({ id: this.asset._id, action: 'open' });
    this.removeLoader('app');
  }

  async created() {
    this.addLoader('app');
    if (this.$route.params.id) {
      const asset = this.$route.params.id;
      this.bookingsFilter.addFilter('asset', asset);
      await this.fetchById(asset);
    } else {
      this.setCurrent(new Asset());
    }
    this.removeLoader('app');
  }
}
